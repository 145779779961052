<template>
  <div class="pipeline-setting" v-if="selectedPipeline != null">
    <div class="header">
      <div class="titleG">
        <div @click="handelCancel($event)" class="fleche">
          <font-awesome-icon class="" icon="arrow-circle-left" />
        </div>
        <div class="titre">
          <h3>Gestion des pipelines : {{ selectedPipeline.name }}</h3>
        </div>
      </div>
      <div class="button">
        <b-button
          class="addstage"
          size="sm"
          variant="success"
          :disabled="getPipelinesLoading"
          @click="addBlockStage"
        >
          <font-awesome-icon class="" icon="plus" />
          Ajouter un stade
        </b-button>
      </div>
    </div>
    <hr />
    <div>
      <b-row>
        <b-col cols="6">
          <b-form-group label="Pipeline :" label-for="nested-street">
            <b-form-input
              id="nested-street"
              v-model="pipelineName"
              @change="updatePipeline"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="Type :" label-for="nested-street">
            <b-form-select
              id="type-input"
              v-model="pipelineType"
              @change="updatePipeline"
              required
            >
              <option
                v-for="type in getProjectsTypes"
                :key="type.id"
                :value="type.id"
              >
                {{ type.name }}
              </option>
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <div class="message">
      <div v-if="getPipelinesLoading" class="three-dots-loading">
        Chargement en cours
      </div>
      <div v-if="getPipelinesError" class="error">
        <ul v-if="Array.isArray(getPipelinesError)">
          <li v-for="(e, index) in getPipelinesError" :key="index">
            {{ e }}
          </li>
        </ul>
        <span v-else>{{ getPipelinesError }}</span>
      </div>
    </div>
    <div class="card-scene" ref="cardScene">
      <Container
        orientation="horizontal"
        @drop="onColumnDrop"
        drag-handle-selector=".column-drag-handle"
        :drop-placeholder="upperDropPlaceholderOptions"
      >
        <Draggable v-for="stage in selectedPipeline.stages" :key="stage.id">
          <div class="column-drag-container">
            <div class="card-column-header">
              <div
                class="column-drag-handle"
                :class="{ 'not-moved': stage.deleted_at == 0 }"
                style="cursor: move;"
              >
                ☰
              </div>
              <div class="input-title-stage">
                <input
                  class="input-focus-stage"
                  placeholder="Nom de stade"
                  @change="updateNameStage($event, stage)"
                  title="Modifier le nom de stade"
                  :value="stage.name"
                  :disabled="stage.deleted_at == 0 ? true : false"
                />
                <span
                  :title="
                    stage.deleted_at == 1
                      ? 'Cliquer pour désactiver ce stade'
                      : 'Cliquer pour activer ce stade'
                  "
                >
                  <b-form-checkbox
                    class="mt-1"
                    v-model="stage.deleted_at"
                    switch
                    size="sm"
                    :value="1"
                    :unchecked-value="0"
                    @change="deletedStage(stage.deleted_at, stage)"
                  >
                  </b-form-checkbox>
                </span>
                <div class="icon-stage">
                  <b-button
                    size="sm"
                    class="btn-left non-drag-area"
                    @click="tryDeleteStage(stage)"
                    title="Supprimer un stade "
                    :disabled="stage.deleted_at == 0 ? true : false"
                  >
                    <font-awesome-icon class icon="times" />
                  </b-button>
                </div>
              </div>
            </div>
            <Container
              group-name="col"
              @drop="onCardDrop($event, stage)"
              drag-handle-selector=".ss-column-drag-handle"
              :drop-placeholder="dropPlaceholderOptions"
              :lock-axis="'y'"
            >
              <Draggable v-for="subStage in stage.subStages" :key="subStage.id">
                <div class="d-flex">
                  <div class="input-title-SS mb-1">
                    <div
                      class="ss-column-drag-handle"
                      :class="{
                        'not-moved': stage.deleted_at == 0
                      }"
                      style="cursor: move;"
                    >
                      ☰
                    </div>
                    <input
                      class="input-focus mr-2"
                      @change="updateNameSousStage($event, subStage)"
                      title="Modifier le nom de sous-stade"
                      :value="subStage.name"
                      placeholder="Nom de sous-stade"
                      :disabled="
                        stage.deleted_at == 0 || subStage.deleted_at == 0
                          ? true
                          : false
                      "
                    />
                  </div>
                  <div class="d-flex justify-content-end">
                    <div class="d-flex justify-content-end">
                      <span
                        :title="
                          subStage.abondan == 1
                            ? 'Cliquer pour désabandonner ce sous stade'
                            : 'Cliquer pour abandonner ce sous stade'
                        "
                      >
                        <b-form-checkbox
                          class="mt-1 abondon"
                          v-model="subStage.abondan"
                          switch
                          size="sm"
                          :value="1"
                          :unchecked-value="0"
                          @change="abondonSubStage(subStage.abondan, subStage)"
                          :disabled="
                            stage.deleted_at == 0 || subStage.deleted_at == 0
                              ? true
                              : false
                          "
                        >
                        </b-form-checkbox>
                      </span>
                    </div>
                    <span
                      :title="
                        subStage.deleted_at == 1
                          ? 'Cliquer pour désactiver ce sous stade'
                          : 'Cliquer pour activer ce sous stade'
                      "
                    >
                      <b-form-checkbox
                        class="mt-1"
                        v-model="subStage.deleted_at"
                        switch
                        size="sm"
                        :value="1"
                        :unchecked-value="0"
                        @change="deletedSubStage(subStage.deleted_at, subStage)"
                        :disabled="stage.deleted_at == 0 ? true : false"
                      >
                      </b-form-checkbox>
                    </span>
                    <div class="d-flex justify-content-end">
                      <b-button
                        size="sm"
                        class="icon-id-pixel"
                        title="Supprimer sous-stade"
                        @click="tryDeleteSubStage(stage, subStage)"
                        :disabled="
                          stage.deleted_at == 0 || subStage.deleted_at == 0
                            ? true
                            : false
                        "
                      >
                        <font-awesome-icon class icon="times" />
                      </b-button>
                    </div>
                  </div>
                </div>

                <div class="d-flex justify-content-end">
                  <span
                    v-if="selectedPipeline.linked_to_pixel == 1"
                    class="icon-pixel"
                    :class="{
                      error: subStage.colorIdPixel
                    }"
                    v-b-toggle="`collapse-${subStage.id}`"
                  >
                    <font-awesome-icon
                      :icon="
                        subStage.colorIdPixel ? 'exclamation-circle' : 'circle'
                      "
                    />
                    ID-Pixel
                  </span>
                  <div class="d-flex">
                    <b-form-select
                      class="select-color"
                      @change="handleColorChange($event, subStage)"
                      :options="[
                        { text: '', value: null },
                        { text: 'Vert', value: 'green' },
                        { text: 'Rouge', value: 'red' },
                        { text: 'Orange', value: 'orange' },
                        { text: 'Jaune', value: 'yellow' },
                        { text: 'Noir', value: 'black' },
                        { text: 'Bleu', value: 'blue' },
                        { text: 'Violet', value: 'purple' }
                      ]"
                      v-model="subStage.color"
                      size="sm"
                      :disabled="
                        stage.deleted_at == 0 || subStage.deleted_at == 0
                          ? true
                          : false
                      "
                      title="Choisir la couleur de sous stade"
                    ></b-form-select>
                    <div class="select-stade">
                      <b-form-select
                        @change="handleStatusChange(subStage)"
                        :options="options"
                        size="sm"
                        v-model="subStage.status"
                        :disabled="
                          stage.deleted_at == 0 || subStage.deleted_at == 0
                            ? true
                            : false
                        "
                        title="Choisir le statut de sous stade"
                      ></b-form-select>
                    </div>
                  </div>
                </div>
                <hr
                  class="line-hr"
                  v-if="selectedPipeline.linked_to_pixel != 1"
                />
                <div
                  class="sous-stade-pixel mt-2"
                  v-if="selectedPipeline.linked_to_pixel == 1"
                >
                  <span class="">Sous-stade Pixel :</span>

                  <b-form-checkbox
                    v-model="subStage.is_pixel"
                    switch
                    size="sm"
                    :value="1"
                    :unchecked-value="0"
                    @change="
                      updateSousStadeIDPixel(subStage, subStage.is_pixel, stage)
                    "
                    :disabled="
                      stage.deleted_at == 0 || subStage.deleted_at == 0
                        ? true
                        : false
                    "
                  >
                  </b-form-checkbox>
                </div>
                <div
                  class="sms-email mt-1"
                  v-if="selectedPipeline && selectedPipeline.is_principal == 1"
                >
                  <span class="title-sms-email">Sous-stade cible :</span>

                  <b-form-select
                    class="select-color w-100px ml-2"
                    :class="{
                      'margin-select':
                        selectedPipeline &&
                        selectedPipeline.linked_to_pixel != 1
                    }"
                    @change="handleSousStadeCibleChange($event, subStage)"
                    :options="[
                      { name: '', id: null },
                      ...computedPipelineListeSousStade(subStage)
                    ]"
                    text-field="name"
                    value-field="id"
                    v-model="subStage.csubstage_cible_id"
                    size="sm"
                    :disabled="
                      stage.deleted_at == 0 || subStage.deleted_at == 0
                        ? true
                        : false
                    "
                  ></b-form-select>
                </div>
                <hr
                  class="line-hr"
                  v-if="selectedPipeline.linked_to_pixel == 1"
                />
                <!-- v-if="selectedPipeline.id == 66" -->
                <div>
                  <div class="sms-email mt-1">
                    <span class="title-sms-email">Envoyer SMS :</span>

                    <b-form-select
                      class="select-color w-100px ml-2"
                      @change="handletemplateSmsChange($event, subStage)"
                      :options="[{ title: '', id: null }, ...getAllTemplateSms]"
                      text-field="title"
                      value-field="id"
                      v-model="subStage.sms_template_id"
                      size="sm"
                      :disabled="
                        stage.deleted_at == 0 || subStage.deleted_at == 0
                          ? true
                          : false
                      "
                      title="Choisir Template SMS"
                    ></b-form-select>
                    <span title="Envoie SMS">
                      <b-form-checkbox
                        v-model="subStage.has_sms"
                        @change="envoyerSmsSubstage($event, subStage)"
                        :value="1"
                        :unchecked-value="0"
                        :disabled="
                          stage.deleted_at == 0 || subStage.deleted_at == 0
                            ? true
                            : false
                        "
                      ></b-form-checkbox
                    ></span>
                  </div>
                  <div class="sms-email mt-1">
                    <span class="title-sms-email">Envoyer E-mail :</span>

                    <b-form-select
                      class="select-color w-100px"
                      @change="handletemplateEmailChange($event, subStage)"
                      :options="[
                        { title: '', id: null },
                        ...getAllTemplateEmail
                      ]"
                      text-field="title"
                      value-field="id"
                      v-model="subStage.email_template_id"
                      size="sm"
                      :disabled="
                        stage.deleted_at == 0 || subStage.deleted_at == 0
                          ? true
                          : false
                      "
                      title="Choisir Template E-mail"
                    ></b-form-select>
                    <span title="Envoie E-mail">
                      <b-form-checkbox
                        v-model="subStage.has_email"
                        @change="envoyerEmailSubstage($event, subStage)"
                        :value="1"
                        :unchecked-value="0"
                        :disabled="
                          stage.deleted_at == 0 || subStage.deleted_at == 0
                            ? true
                            : false
                        "
                      ></b-form-checkbox
                    ></span>
                  </div>
                </div>
                <!-- <hr class="line-hr" /> -->
                <b-collapse :id="`collapse-${subStage.id}`">
                  <div v-for="(item, index) in getListeTypePixel" :key="index">
                    <label class="label-pixel"
                      >Sous-stade Pixel - {{ item.name }}</label
                    >
                    <multiselect
                      @input="updateIdPixelSousStage($event, subStage, item.id)"
                      :value="
                        $options.filters.formatStatus(
                          subStage.substage_pixel_id &&
                            subStage.substage_pixel_id.length
                            ? subStage.substage_pixel_id[index]
                            : subStage.substage_pixel_id,
                          statusPixel[index]
                        )
                      "
                      :options="statusPixel[index] || []"
                      :loading="statusPixelLoading"
                      :multiple="false"
                      :close-on-select="true"
                      :allow-empty="false"
                      label="libelle"
                      track-by="id"
                      :disabled="
                        stage.deleted_at == 0 || subStage.deleted_at == 0
                          ? true
                          : false
                      "
                    >
                      <span slot="noResult">Aucun statut trouvé.</span>
                      <span slot="noOptions">Aucun statut trouvé.</span>
                    </multiselect>
                  </div>
                </b-collapse>
              </Draggable>
            </Container>
            <div class="btn-add-SS">
              <b-button
                size="sm"
                variant="success"
                @click="addSousStage(stage)"
                :disabled="
                  getPipelinesLoading || (stage.deleted_at == 0 ? true : false)
                "
              >
                <font-awesome-icon class="" icon="plus" />
                Sous-stade
              </b-button>
            </div>
          </div>
        </Draggable>
      </Container>
    </div>
    <b-modal
      no-close-on-backdrop
      ref="deleteStageModal"
      id="deleteStageModal"
      title="Supprimer un stade"
      :hide-footer="true"
      @hidden="resetModal"
    >
      <p class="mb-3">
        Êtes-vous certain de vouloir supprimer le stade
        <strong>
          {{ stageToDelete ? stageToDelete.name : '' }}
        </strong>
        ?
      </p>
      <div class="message">
        <div v-if="getPipelinesLoading" class="loading">
          Chargement en cours...
        </div>
        <div v-if="getPipelinesError" class="error">
          <ul v-if="Array.isArray(getPipelinesError)">
            <li v-for="(e, index) in getPipelinesError" :key="index">
              {{ e }}
            </li>
          </ul>
          <span v-else>{{ getPipelinesError }}</span>
        </div>
      </div>
      <div class="form-actions">
        <b-button
          @click="hideModal('deleteStageModal')"
          variant="outline-secondary"
        >
          Annuler
        </b-button>
        <b-button variant="success" @click="deleteStage">
          Valider
        </b-button>
      </div>
    </b-modal>
    <b-modal
      no-close-on-backdrop
      ref="deleteSubStageModal"
      id="deleteSubStageModal"
      title="Supprimer un sous-stade"
      :hide-footer="true"
      @hidden="resetModal"
    >
      <template v-if="nextEtape == false">
        <p class="mb-3">
          Pour remplacer les projets de sous_stade
          <strong>
            {{ subStageToDelete ? subStageToDelete.name : '' }}
          </strong>
          vers une autre, pouvez vous selectionner une stade/sous-stade :
        </p>
        <div class="select mb-3">
          <div>stade :</div>
          <multiselect
            placeholder="Rechercher stade"
            :multiple="false"
            v-model="stage_id"
            :options="selectedPipeline.stages"
            :close-on-select="true"
            :clear-on-select="true"
            :preserve-search="true"
            label="name"
            track-by="id"
            :preselect-first="false"
            @select="changeSubstage($event)"
          >
            <span slot="noResult">Aucun stade trouvé.</span>
            <span slot="noOptions">Aucun stade trouvé.</span>
          </multiselect>
        </div>

        <div class="select mb-3">
          Sous-stade :
          <multiselect
            placeholder="Rechercher sous-stade"
            :multiple="false"
            :options="substages"
            v-model="substage_id"
            :close-on-select="true"
            :clear-on-select="true"
            :preserve-search="true"
            label="name"
            track-by="id"
            :preselect-first="false"
          >
            <span slot="noResult">Aucun sous-stade trouvé.</span>
            <span slot="noOptions">Aucun sous-stade trouvé.</span>
          </multiselect>
        </div>
      </template>
      <template v-else>
        <p class="mb-3">
          Le sous stade
          <strong>
            {{ subStageToDelete ? subStageToDelete.name : '' }}
          </strong>
          sera supprimer et tous les projets dedans seront déplacer vers le
          stade <strong>{{ stage_id.name }} </strong> et sous stade
          <strong> {{ substage_id.name }} </strong>
          .
        </p>
      </template>
      <div class="message">
        <div v-if="getPipelinesLoading" class="loading">
          Chargement en cours...
        </div>
        <div v-if="getPipelinesError" class="error">
          <ul v-if="Array.isArray(getPipelinesError)">
            <li v-for="(e, index) in getPipelinesError" :key="index">
              {{ e }}
            </li>
          </ul>
          <span v-else>{{ getPipelinesError }}</span>
        </div>
        <div v-if="error" class="error">
          <ul v-if="Array.isArray(error)">
            <li v-for="(e, index) in error" :key="index">
              {{ e }}
            </li>
          </ul>
          <span v-else>{{ error }}</span>
        </div>
      </div>
      <div class="form-actions">
        <b-button
          @click="hideModal('deleteSubStageModal')"
          variant="outline-secondary"
        >
          Annuler
        </b-button>
        <b-button
          v-if="nextEtape == false"
          variant="success"
          @click="confirmationDeleteSubstage"
        >
          Suivant
        </b-button>
        <b-button v-else variant="success" @click="deleteSubStage">
          Valider
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { Container, Draggable } from 'vue-smooth-dnd'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    Container,
    Draggable
  },
  data() {
    return {
      selectedPipeline: null,
      pipelineName: null,
      pipelineType: null,
      upperDropPlaceholderOptions: {
        animationDuration: '150',
        showOnTop: true
      },
      dropPlaceholderOptions: {
        animationDuration: '150',
        showOnTop: true
      },
      options: [
        { value: 'open', text: 'Ouvert' },
        { value: 'lost', text: 'Perdu' },
        { value: 'won', text: 'Conclu' }
      ],
      statusPixel: [],
      statusPixelLoading: false,
      statusVERTUOZ: [],
      statusVERTUOZLoading: false,
      statusACF: [],
      statusACFLoading: false,
      statusFIBER: [],
      statusFIBERLoading: false,
      statusRSE: [],
      statusRSELoading: false,
      statusGSE: [],
      statusGSELoading: false,
      stageToDelete: null,
      selectedStage: null,
      subStageToDelete: null,
      substage_id: null,
      stage_id: null,
      substages: [],
      nextEtape: false,
      error: null
    }
  },

  methods: {
    ...mapActions([
      'fetchProjetsTypes',
      'fetchPipelinesPipelines',
      'deletePipelinesPipeline',
      'updatePipelinesPipeline',
      'addPipelinesStages',
      'deletePipelinesStages',
      'updatePipelinesStages',
      'addPipelinesSubStages',
      'deletePipelinesSousStages',
      'updatePipelinesSubStages',
      'fetchPixelStatus',
      'resetErrorPipeline',
      'fetchAllSmsTemplates',
      'fetchAllEmailTemplates',
      'fetchPipelinesCategoriesPipelines',
      'fetchListTypePixel',
      'fetchPipelinesListeSubStages',
      'deletedPipelineSubStageSwitch',
      'deletedPipelineStageSwitch'
    ]),
    async handleStatusChange(subStage) {
      const response = await this.updatePipelinesSubStages({
        subStage: subStage,
        data: { status: subStage.status }
      })
      if (response) {
        this.refreshPipeline()
      }
    },
    async handleColorChange(color, subStage) {
      const response = await this.updatePipelinesSubStages({
        subStage: subStage,
        data: { color: subStage.color }
      })
      if (response) {
        this.refreshPipeline()
      }
    },
    async updatePipeline() {
      if (
        this.pipelineName == this.selectedPipeline.name &&
        this.pipelineType == this.selectedPipeline.type.id
      ) {
        return
      }

      const data = {}

      if (this.pipelineName != this.selectedPipeline.name) {
        data.name = this.pipelineName
      }
      if (this.piplineType != this.selectedPipeline.type.id) {
        data.type = this.pipelineType
      }
      const response = await this.updatePipelinesPipeline({
        pipeline: this.selectedPipeline,
        data: data
      })
      if (response) {
        this.$bvToast.toast('Données envoyées avec succès', {
          title: 'Succès',
          autoHideDelay: 2000,
          toaster: 'b-toaster-bottom-right',
          variant: 'success',
          solid: true
        })
      }
    },
    async addBlockStage() {
      let count = this.selectedPipeline.stages.length
      const stage = {
        name: 'Nouveau stade ' + count,
        order: count
      }

      await this.addPipelinesStages({
        pipeline: this.selectedPipeline,
        stage: stage
      })
      this.$nextTick(() => {
        this.$refs.cardScene.scrollLeft = this.$refs.cardScene.scrollWidth
      })
    },
    async updateNameStage(e, stage) {
      const response = await this.updatePipelinesStages({
        stage: stage,
        data: { name: e.target.value }
      })
      if (response) {
        this.refreshPipeline()
      }
    },
    async envoyerSmsSubstage(e, subStage) {
      const response = await this.updatePipelinesSubStages({
        subStage: subStage,
        data: { has_sms: e }
      })
      if (response) {
        this.refreshPipeline()
      }
    },
    async updateSousStadeIDPixel(subStage, e, stage) {
      const response = await this.updatePipelinesSubStages({
        subStage: subStage,
        stage: stage,
        data: { is_pixel: e }
      })
      if (response) {
        this.refreshPipeline()
      }
    },
    async handletemplateSmsChange(e, subStage) {
      const response = await this.updatePipelinesSubStages({
        subStage: subStage,
        data: { sms_template_id: e }
      })
      if (response) {
        this.refreshPipeline()
      }
    },
    async handleSousStadeCibleChange(e, subStage) {
      const response = await this.updatePipelinesSubStages({
        subStage: subStage,
        data: { csubstage_cible_id: e }
      })
      if (response) {
        this.refreshPipeline()
      }
    },
    async envoyerEmailSubstage(e, subStage) {
      const response = await this.updatePipelinesSubStages({
        subStage: subStage,
        data: { has_email: e }
      })
      if (response) {
        this.refreshPipeline()
      }
    },
    async handletemplateEmailChange(e, subStage) {
      const response = await this.updatePipelinesSubStages({
        subStage: subStage,
        data: { email_template_id: e }
      })
      if (response) {
        this.refreshPipeline()
      }
    },
    tryDeleteStage(stage) {
      this.stageToDelete = stage
      this.$refs['deleteStageModal'].show()
    },
    async deleteStage() {
      const response = await this.deletePipelinesStages({
        pipeline: this.selectedPipeline,
        stage: this.stageToDelete
      })
      if (response) {
        this.hideModal('deleteStageModal')
      }
    },
    tryDeleteSubStage(stage, subStage) {
      this.selectedStage = stage
      this.subStageToDelete = subStage
      this.$refs['deleteSubStageModal'].show()
    },
    async abondonSubStage(e, subStage) {
      const response = await this.updatePipelinesSubStages({
        subStage: subStage,
        data: { abondan: e }
      })
      if (response) {
        this.refreshPipeline()
      }
    },
    validateForm(stage, substage) {
      const validate = {
        status: 'success',
        errors: []
      }
      if (!stage) {
        validate.status = 'error'
        validate.errors.push('Le champ stade est obligatoire')
      }
      if (!substage) {
        validate.status = 'error'
        validate.errors.push('Le champ sous-stade est obligatoire')
      }
      return validate
    },
    confirmationDeleteSubstage() {
      if (this.stage_id && this.substage_id) {
        this.nextEtape = true
        this.error = null
      }
      const validate = this.validateForm(this.stage_id, this.substage_id)
      if (validate.status === 'error') {
        this.error = validate.errors
        return
      }
    },
    async deleteSubStage() {
      const response = await this.deletePipelinesSousStages({
        stage: this.stage_id,
        substage: this.substage_id,
        csubStage: this.subStageToDelete,
        cstage: this.selectedStage
      })
      if (response) {
        if (
          this.selectedPipeline &&
          this.selectedPipeline.id &&
          this.selectedPipeline.is_principal == 1
        ) {
          this.fetchPipelinesListeSubStages(this.selectedPipeline.id)
        }
      }
      this.hideModal('deleteSubStageModal')
    },

    hideModal(ref) {
      this.$refs[ref].hide()
      this.resetModal()
    },

    resetModal() {
      this.stageToDelete = null
      this.selectedStage = null
      this.subStageToDelete = null
      this.substage_id = null
      this.stage_id = null
      this.substages = []
      this.nextEtape = false
      this.resetErrorPipeline()
      this.error = null
    },
    async refreshPipeline() {
      await this.fetchPipelinesPipelines({ is_hidden: 1 })
      if (this.getPipelinesPipelines && this.getPipelinesPipelines.length) {
        this.selectedPipeline = this.getPipelinesPipelines.find(
          pipeline => pipeline.id == this.$route.params.id
        )
      }
      this.computedColoPixelId(this.selectedPipeline.stages)
    },
    async addSousStage(stage) {
      let count = stage.subStages.length
      const data = new URLSearchParams()
      if (this.getListeTypePixel && this.getListeTypePixel.length) {
        for (let key = 0; key < this.getListeTypePixel.length; key++) {
          data.append(
            'pixel_types[' + key + '][pixel_type_id]',
            this.getListeTypePixel[key].id
          )
          data.append('pixel_types[' + key + '][substage_pixel_id]', '')
        }
      }
      data.append('name', 'Nouveau sous-stade ' + count)
      data.append('order', count)
      data.append('id_cstage', stage.id)
      data.append('status', 'open')
      const response = await this.addPipelinesSubStages({ stage, data: data })
      if (response) {
        this.refreshPipeline()
        if (
          this.selectedPipeline &&
          this.selectedPipeline.id &&
          this.selectedPipeline.is_principal == 1
        ) {
          this.fetchPipelinesListeSubStages(this.selectedPipeline.id)
        }
      }
    },

    async updateNameSousStage(e, subStage) {
      const response = await this.updatePipelinesSubStages({
        subStage,
        data: {
          name: e.target.value
        }
      })
      if (response) {
        this.refreshPipeline()
      }
    },
    async deletedSubStage(e, subStage) {
      const response = await this.deletedPipelineSubStageSwitch({
        subStage,
        data: {
          is_hidden: e == 1 ? 0 : 1,
          csubstage_id: subStage.id
        }
      })
      if (response) {
        this.refreshPipeline()
      }
    },
    async deletedStage(e, stage) {
      const response = await this.deletedPipelineStageSwitch({
        data: {
          is_hidden: e == 1 ? 0 : 1,
          cstage_id: stage.id
        }
      })
      if (response) {
        this.refreshPipeline()
      }
    },
    async updateIdPixelSousStage(e, subStage, id_pixel) {
      const data = new URLSearchParams()
      if (this.getListeTypePixel && this.getListeTypePixel.length) {
        for (let key = 0; key < this.getListeTypePixel.length; key++) {
          if (id_pixel == this.getListeTypePixel[key].id) {
            data.append('pixel_types[' + key + '][pixel_type_id]', id_pixel)
            data.append(
              'pixel_types[' + key + '][substage_pixel_id]',
              e.id ? e.id : ''
            )
          }
        }
      }
      const response = this.updatePipelinesSubStages({
        subStage,
        data: data
      })
      if (response) {
        this.refreshPipeline()
      }
    },

    onColumnDrop(dropResult) {
      const { removedIndex, addedIndex } = dropResult
      if (removedIndex === addedIndex) {
        return
      }
      this.selectedPipeline.stages = this.applyDrag(
        this.selectedPipeline.stages,
        dropResult
      )

      this.updatePipelinesStages({
        stage: this.selectedPipeline.stages[addedIndex],
        data: {
          order: addedIndex
        }
      })
    },

    async onCardDrop(dropResult, stage) {
      const { removedIndex, addedIndex } = dropResult
      if (removedIndex === addedIndex) {
        return
      }

      stage.subStages = this.applyDrag(stage.subStages, dropResult)

      const response = await this.updatePipelinesSubStages({
        subStage: stage.subStages[addedIndex],
        data: { order: addedIndex }
      })
      if (response) {
        this.refreshPipeline()
      }
    },

    getCardPayload(columnId) {
      return index => {
        return this.selectedPipeline.stages.filter(
          stage => stage.id === columnId
        )[0].subStages[index]
      }
    },

    handelCancel() {
      if (
        this.$route &&
        this.$route.name === 'categorieProjetPipelineUpdate' &&
        this.$route.params &&
        this.$route.params.id &&
        this.$route.params.idPipeline
      ) {
        this.$router.push(
          '/setting/categorie-projet/' + this.$route.params.id + '/pipeline'
        )
      } else {
        this.$router.push('/setting/pipeline')
      }
    },
    applyDrag(arr, dragResult) {
      const { removedIndex, addedIndex, payload } = dragResult
      if (removedIndex === null && addedIndex === null) return arr
      const result = [...arr]
      let itemToAdd = payload
      if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0]
      }
      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd)
      }
      for (let index = 0; index < result.length; index++) {
        result[index].order = index
      }
      return result
    },
    changeSubstage(stage) {
      this.substage_id = null
      this.substages = []
      for (let index = 0; index < stage.subStages.length; index++) {
        const subStages = stage.subStages
        if (subStages) {
          this.substages = subStages.filter(s => {
            return s.id != this.subStageToDelete.id
          })
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      'getPipelinesPipelines',
      'getProjectsTypes',
      'getPipelinesLoading',
      'getPipelinesError',
      'getAllTemplateSms',
      'getAllTemplateEmail',
      'getPipelineCategory',
      'getListeTypePixel',
      'getPipelineListeSousStade'
    ]),
    computedPipelineListeSousStade() {
      return function(data) {
        return this.getPipelineListeSousStade.filter(item => item.id != data.id)
      }
    },
    computedColoPixelId() {
      return function(data) {
        for (let j = 0; j < data.length; j++) {
          if (data[j].subStages && data[j].subStages.length) {
            for (let index = 0; index < data[j].subStages.length; index++) {
              if (
                data[j].subStages[index].substage_pixel_id &&
                data[j].subStages[index].substage_pixel_id.length
              ) {
                for (
                  let key = 0;
                  key < data[j].subStages[index].substage_pixel_id.length;
                  key++
                ) {
                  if (
                    !data[j].subStages[index].substage_pixel_id[key]
                      .substage_pixel_id
                  ) {
                    data[j].subStages[index].colorIdPixel = true
                  }
                }
              }
            }
          }
        }
      }
    }
  },
  filters: {
    formatStatus(value, list) {
      if (!value || !list) {
        return null
      }
      return list.find(e => e.id == value.substage_pixel_id)
    }
  },
  async mounted() {
    if (!this.getProjectsTypes || !this.getProjectsTypes.length) {
      this.fetchProjetsTypes()
    }

    if (
      this.$route &&
      this.$route.name === 'categorieProjetPipelineUpdate' &&
      this.$route.params &&
      this.$route.params.id
    ) {
      if (!this.getPipelineCategory || !this.getPipelineCategory.length) {
        await this.fetchPipelinesCategoriesPipelines(this.$route.params.id)
      }
    } else {
      if (!this.getPipelinesPipelines || !this.getPipelinesPipelines.length) {
        await this.fetchPipelinesPipelines({ is_hidden: 1 })
      }
    }
    let search
    if (
      this.$route &&
      this.$route.name === 'categorieProjetPipelineUpdate' &&
      this.$route.params &&
      this.$route.params.idPipeline
    ) {
      search = this.getPipelineCategory.find(
        pipeline => pipeline.id == this.$route.params.idPipeline
      )
    } else {
      search = this.getPipelinesPipelines.find(
        pipeline => pipeline.id == this.$route.params.id
      )
    }

    if (!search) {
      this.$router.push('/setting/pipeline')
    }
    this.selectedPipeline = search
    this.pipelineName = search.name
    this.pipelineType = search.type.id
    this.computedColoPixelId(this.selectedPipeline.stages)
    if (this.selectedPipeline && this.selectedPipeline.linked_to_pixel == 1) {
      if (!this.getListeTypePixel || !this.getListeTypePixel.length) {
        await this.fetchListTypePixel()
      }
      this.statusPixelLoading = true
      if (this.getListeTypePixel && this.getListeTypePixel.length) {
        for (let index = 0; index < this.getListeTypePixel.length; index++) {
          await this.fetchPixelStatus({
            pixel_id: this.getListeTypePixel[index].id
          }).then(data => {
            this.statusPixel[index] = [
              {
                id: null,
                libelle: '-- Aucun --'
              },
              ...data
                .filter(e => !e.deleted && e.active)
                .sort((a, b) => {
                  if (a.libelle < b.libelle) {
                    return -1
                  }
                  if (a.libelle > b.libelle) {
                    return 1
                  }
                  return 0
                })
            ]
            this.statusPixelLoading = false
          })
        }
      }
    }
    this.fetchAllSmsTemplates()
    this.fetchAllEmailTemplates()
    if (
      this.selectedPipeline &&
      this.selectedPipeline.id &&
      this.selectedPipeline.is_principal == 1
    ) {
      this.fetchPipelinesListeSubStages(this.selectedPipeline.id)
    }
    this.resetErrorPipeline()
  }
}
</script>
<style lang="scss" scoped>
.pipeline-setting {
  height: 100%;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    white-space: nowrap;
    .titleG {
      display: flex;
      align-items: center;
    }
    .titre {
      margin-left: 10px;
    }
  }
  .button {
    .addstage {
      font-size: 15px;
    }
  }
  .message {
    height: 25px;
  }
  .select-color {
    margin-right: 5px;
    &.margin-select {
      margin-right: 47px;
    }
  }
  .w-100px {
    width: 110px;
  }
  .btn-add-SS {
    .btn-ss {
      border-radius: 50px;
    }
    text-align: center;
    padding: 5px;
  }
  .fleche {
    padding-bottom: 8px;
    font-size: 22px;
    cursor: pointer;
  }
  .card-scene {
    height: calc(100% - 180px);
  }
  .btn-secondary:not(:disabled):not(.disabled):active,
  .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    box-shadow: none;
    background-color: transparent;
    color: #7b8785;
    background-color: transparent;
    border-color: transparent;
  }
}
#deleteSubStageModal {
  .select {
    display: flex !important;
    align-items: center;
    justify-content: space-between;
  }
}
.column-drag-handle,
.ss-column-drag-handle {
  position: absolute;
  top: 11px;
  left: 6px;
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  &.not-moved {
    pointer-events: none;
  }
}
.multiselect {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  display: block;
  position: relative;
  width: 76%;
  min-height: 40px;
  text-align: left;
  color: #35495e;
}
.ss-column-drag-handle {
  top: 5px;
  left: 3px;
}

.input-title-stage,
.input-title-SS {
  display: flex;
  justify-content: space-between;
  margin-left: 25px;
  .input-focus {
    width: 147px;
    &:focus {
      background-color: #f8f9fa;
    }
  }
  .input-focus-stage {
    &:focus {
      background-color: #e1f6e3;
    }
  }
  input {
    color: black;
    overflow: visible;
    background-color: transparent;
    border: 0px;
    font-size: 13px;
    text-overflow: ellipsis;
  }
}
.sms-email {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  white-space: nowrap;
  .title-sms-email {
    font-size: 11px;
    font-weight: 600;
  }
}
.sous-stade-pixel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 11px;
  font-weight: 600;
}
.line-hr {
  margin-top: 5px;
  margin-bottom: 5px;
}
.label-pixel {
  margin: 4px 0 2px 4px;
  font-size: 13px;
}
.input-id-pixel {
  color: black;
  overflow: visible;
  background-color: transparent;
  border: 0px;
  font-size: 13px;
  text-overflow: ellipsis;
}
.abondon {
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #007bff;
    background-color: #007bff;
  }
}
.icon-id-pixel {
  color: #7b8785;
  background-color: transparent;
  border-color: transparent;
  box-shadow: unset;
  margin-right: 3px;
}
.icon-pixel {
  font-size: 64%;
  font-weight: 700;
  line-height: 0;
  white-space: nowrap;
  border-radius: 0.25rem;
  margin: 4px;
  padding: 4px;
  margin-right: 14px;
  color: #28a745;
  background-color: #e1f6e3;
  border: 1px solid #28a745;
  &.error {
    color: #ff6961;
    background-color: #ff696133;
    border: 1px solid #ff6961;
  }
}
hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.icon-stage,
.btn-s-stage {
  .btn-left,
  .color-btn-ss {
    color: #495057ab;
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;
    margin-right: 3px;
  }
  .btn-secondary:focus,
  .btn-secondary.focus {
    color: #495057ab;
    background-color: rgba(255, 255, 255, 0.075);
    border-color: rgba(255, 255, 255, 0.075);
  }
}

.btn-sm,
.btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.card-column-header {
  position: relative;
  width: 100%;
  padding: 9px;
}
.smooth-dnd-container.horizontal {
  display: flex;
}
.smooth-dnd-container.vertical > .smooth-dnd-draggable-wrapper {
  position: relative;
  overflow: visible;
  margin-top: 5px;
  border: 1px solid #6c757d52;
  padding: 4px;
  background-color: #b0f2b652;
  width: 100%;
  border-radius: 4px;
}
p {
  margin-top: 0;
  margin-bottom: 0rem;
}
.smooth-dnd-container {
  margin: 3px;
}
.smooth-dnd-container {
  position: relative;
  min-height: 30px;
  min-width: 255px;
}
.smooth-dnd-container.horizontal > .smooth-dnd-draggable-wrapper {
  border: 1px solid #6c757d4d;
  margin-right: 8px;
  background-color: #ebebeb5c;
  border-radius: 4px;
}
.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 12px;
  width: 77px;
}
</style>
<style scoped>
.slide-toggle {
  display: none;
}
.slidemenu {
  border: 0px;
  background-color: transparent;
  overflow: hidden;
}

.slidemenu label {
  width: 25%;
  text-align: center;
  display: block;
  float: left;
  color: #333;
  opacity: 4.2;
}

.slidemenu label:hover {
  cursor: pointer;
  color: #666;
}

.slidemenu label span {
  display: block;
  padding: 10px;
}

.slidemenu label .icon {
  font-size: 20px;
  border: solid 2px #333;
  text-align: center;
  height: 50px;
  width: 50px;
  display: block;
  margin: 0 auto;
  line-height: 50px;
}
@media screen and (max-width: 580px) {
  .header {
    white-space: unset;
  }
  .card-scene {
    width: 100%;
    overflow: auto;
  }
}
</style>
<style lang="scss">
.abondon {
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #007bff;
    background-color: #007bff;
  }
}
</style>
